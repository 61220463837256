import * as React from "react"
import { Header } from "../header"
import { Footer } from "../footer"
import { styles } from "./layout.css"

interface LayoutProps {
    children?: React.ReactNode,
    additionalHeaderItems?: React.ReactNode,
}

const Layout: React.FC<LayoutProps> = ({ additionalHeaderItems, children }) => {
    const headerLinks = [
        { name: "Home", path: "/" },
        { name: "Search", path: "/search" },
        { name: "Privacy Policy", path: "/privacy-policy" },
        { name: "Terms of Service", path: "/terms-of-service" }
    ]

    const footerLinks = [
        { name: "About Us", path: "/about-us" },
        { name: "Contact Us", path: "/contact-us" }, ...headerLinks
    ]

    return (
        <>
            <Header siteTitle="" links={headerLinks} />
            <div className={styles.layoutContent}>
                <main>
                    {children}
                </main>
            </div>
            <Footer links={footerLinks} />
        </>
    )
}

export default Layout