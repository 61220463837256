// src/context/SearchContext.tsx
import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { SearchResult } from '../service/books';

interface SearchContextType {
    savedSearch: SavedSearch | null;
    setSavedSearch: (results: SavedSearch | null) => void;
}

interface SavedSearch {
    searchResult?: SearchResult;
    page?: number;
    query?: string;
    scroll?: number;
}

const defaultState = {
    savedSearch: null,
    setSavedSearch: (results: SavedSearch | null) => {}
  }

const SearchContext = createContext<SearchContextType>(defaultState);

const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [savedSearch, setSavedSearch] = useState<SavedSearch | null>(() => {
        const savedResults = localStorage.getItem('searchResults');
        return savedResults ? JSON.parse(savedResults) : null;
    });

    useEffect(() => {
        if (savedSearch) {
            localStorage.setItem('searchResults', JSON.stringify(savedSearch));
        }
    }, [savedSearch]);

    return (
        <SearchContext.Provider value={{ savedSearch, setSavedSearch }}>
            {children}
        </SearchContext.Provider>
    );
};

const useSearchContext = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearchContext must be used within a SearchProvider');
    }
    return context;
};

export { SearchProvider, useSearchContext };
