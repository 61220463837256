import { Link } from "../../agnostics"
import * as React from "react"
import { styles } from "./footer.css"

interface FooterProps {
    links: Array<{ name: string, path: string }>
}

export const Footer: React.FC<FooterProps> = ({ links }) => {
    return (
        <footer className={styles.container}>
            <div className={styles.content}>
                <ul className={styles.list}>
                    {links.map((link) => (
                        <li key={link.name} >
                            <Link to={link.path} className={styles.items} >
                                {link.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className={styles.copyright}>
                    <div>
                        <div >Copyright © Skedbooks 2024. All rights reserved
                            <br />
                            <Link to={"/privacy-policy"} className={styles.link} >
                                Privacy Policy
                            </Link>
                            &nbsp;|&nbsp;
                            <Link to={"/terms-of-service"} className={styles.link} >
                                Terms of Service
                            </Link>
                        </div>
                        <div>Download app on &nbsp;
                            <a href="https://play.google.com/store/apps/details?id=com.sked.books&hl=en_IN"
                                target="_blank"
                                className={styles.link}>Google Play</a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://apps.apple.com/in/app/skedbooks/id6621180292"
                                target="_blank"
                                className={styles.link}>App Store</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}