import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import "./src/styles/global.css"

import React from 'react';
import { SearchProvider } from './src/context/search-context';
import { GatsbyBrowser } from 'gatsby';

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
    <SearchProvider>{element}</SearchProvider>
);

const searchTargetPath = "/search/"; // Change this to your target page path
const homeTargetPath = "/"
export const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({ location, prevLocation }) => {
    if (prevLocation && prevLocation.pathname === searchTargetPath) {
        sessionStorage.setItem("@@scroll-search", `${window.scrollY}`);
    }
    if (prevLocation && prevLocation.pathname === homeTargetPath) {
        const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions')!) || {};
        const scrollableSections = document.querySelectorAll('.shelve');

        scrollableSections.forEach((section) => {
            scrollPositions[`@@scroll-home-${section.id}`] = section.scrollLeft;
        });

        sessionStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
    }
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
    if (location.pathname === searchTargetPath) {
        //setTimeout(() => {
        // window.scrollTo({
        //     left: 0,
        //     top: Number(sessionStorage.getItem("@@scroll-search") ?? 0),
        //     behavior: "smooth",
        // })
        //}, 0);
    }

    if (location.pathname === homeTargetPath) {
        const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions')!) || {};
        const scrollableSections = document.querySelectorAll('.shelve');

        scrollableSections.forEach((section) => {
            const scrollPosition = scrollPositions[`@@scroll-home-${section.id}`] || 0;
            section.scrollLeft = scrollPosition;
        });
    }

    const locations = (window as Window).locations || [document.referrer];
    locations.push(window.location.href);
    (window as Window).locations = locations;
    (window as Window).previousPath = locations[locations.length - 2];
};

interface Window {
    locations?: string[];
    previousPath?: string;
}